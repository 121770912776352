import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {
  isMobile,
  isIE,
  isSafari,
  isIOS,
  isMobileSafari,
  isAndroid,
} from "react-device-detect";
import logo from "../../images/connect-logo.png";

const AppUrl = () => {
  const renderMobileMessage = () => {
    if (isIOS) {
      return (
        <Typography
          variant="body1"
          style={{ marginTop: "50px", textAlign: "center" }}
        >
          Thank you for signing up!
          <br/>
          <br/>
          Please download the Connect app for your device using the button below and sign in there with the username and password you just created.{' '}
          <br/>
          <br/>
          <a
            href="https://apps.apple.com/gb/app/smartspotter-earn-money/id468416419"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://www.smartspotter.com/application/files/2116/6418/1072/badge-apple.svg"
              alt="Download on the App Store"
              style={{ width: '150px', height: 'auto' }}
            />
          </a>
        </Typography>
      );
    } else if (isAndroid) {
      return (
        <Typography
          variant="body1"
          style={{ textAlign: "center" }}
        >
          Thank you for signing up!
          <br/>
          <br/>
          Please download the Connect app for your device using the button below and sign in there with the username and password you just created.{' '}
          <br/>
          <br/>
          <br/>
          <br/>
          <a
            href="https://play.google.com/store/apps/details?id=com.smartspotter.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://www.smartspotter.com/application/files/3316/6418/2454/badge-google.svg"
              alt="Download on the App Store"
              style={{ width: '250px', height: 'auto' }}
            />
          </a>

        </Typography>
      );
    } else {
      return (
        <Typography
          variant="body1"
          style={{ textAlign: "center" }}
        >

          Thank you for signing up!
          <br/>
          <br/>
          Please download the Connect app for your device using the buttons below and sign in there with the username and password you just created.{' '}
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <a
            href="https://play.google.com/store/apps/details?id=com.smartspotter.app"
            target="_blank"
            rel="noopener noreferrer"
            style={{padding: "25px"}}
          >
            <img
              src="https://www.smartspotter.com/application/files/3316/6418/2454/badge-google.svg"
              alt="Download on the App Store"
              style={{ width: '300px', height: 'auto' }}
            />
          </a>
          <a
            href="https://apps.apple.com/gb/app/smartspotter-earn-money/id468416419"
            target="_blank"
            rel="noopener noreferrer"
            style={{padding: "25px"}}

          >
            <img
              src="https://www.smartspotter.com/application/files/2116/6418/1072/badge-apple.svg"
              alt="Download on the App Store"
              style={{ width: '300px', height: 'auto' }}
            />
          </a>

        </Typography>)
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "20px",
      }}
    >
      <img style={{marginTop: "10px", marginBottom: "10px"}} src={logo} />
      <p style={{ marginTop: "50px" }}>{renderMobileMessage()}</p>
    </Box>
  );
};

export default AppUrl;
